<template>
    <div class="scrollable">
        <div class="voice__channel">
            <div class="__loading" v-if="loadingTemplate">
                <div class="div" style="height: 80vh" v-loading="loadingTemplate">
                </div>
            </div>
            <div id="__messsages" v-if="!loadingTemplate">
                <div class="messages__navigation contains-main-voice-buttons">
                    <div class="left-side-tab">
                        <button class="__reporting__nav is-active-tab">
                            {{ $t("dashboard.voice_templates.template") }}
                        </button>
                        <button @click="scheduleMessage">
                            {{ $t("dashboard.schedule_voice.scheduled_voice") }}
                        </button>
                    </div>
                    <!-- Right side -->
                    <div class="right-side-tab">
                        <button class="__reporting__nav is-create-button" @click="openMessageModal('template')">
                            {{ $t("dashboard.voice_templates.table.create_voice_template") }} 
                        </button>
                        <!-- <button @click="scheduleMessagete_">
                            Scheduled Voice Messages
                        </button> -->
                        <!-- Send Message Button -->
                        <button type="button" class="is-create-button"
                            v-if="Array.isArray(messageTemplates) && messageTemplates.length > 0"
                            @click="openMessageModal('sendMessage')">
                            <img class="mr-1" src="../../../assets/send__light.svg" alt="">
                            
                            {{ $t("dashboard.send_voice_message.send") }} 
                        </button>
                    </div>
                </div>
                <!-- <button class="__float__btn" style="width: 184px;"  v-if="Array.isArray(messageTemplates) && messageTemplates.length > 0" @click="openMessageModal('sendMessage')">
                    <img class="mr-1" src="../../../assets/send__light.svg" alt="">
                    Send Voice Message
                </button> -->
                <div class="__main_messages">
                    <!-- MESSAGES EMPTY STATE -->
                    <div class="__empty__state">
                        <div id="__messages__card" class="mnotify-card" v-if="!messageTemplates.length">
                            <div id="side-indicators-content" class="mnotify-card-header">
                                <h3 class="mnotify-card-header-text py-0 my-0">
                                    {{ $t("dashboard.voice_templates.title") }} 
                            </h3>
                            </div>
                            <div class="mnotify-card-body">
                                <div class="__nodata_messages">
                                    <div class="_no_message_header table_wrapper">
                                        <div class="__table">
                                            <table class="__mnotify__table">
                                                <thead class="__mnotify__table__header">
                                                    <tr>
                                                        <th class="__mnotify__table__header_first tb__messages">   {{ $t("dashboard.voice_templates.table.name") }} </th>
                                                        <th class="tb__date_time">
                                                            {{ $t("dashboard.voice_templates.table.date_time") }} </th>
                                                        <th class="tb__action">
                                                            {{ $t("dashboard.voice_templates.table.actions") }} </th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="no_message_message">
                                        <h3 class="__nodata__header text-center">
                                            {{ $t("dashboard.voice_templates.table.no_templates") }}</h3>
                                        <button class="__create_a_new_template"
                                            @click="openMessageModal('sendMessage')">
                                            
                                            {{ $t("dashboard.voice_templates.new") }} </button>
                                        <div class="__button_wrapper">
                                            <button @click="openMessageModal('sendMessage')">
                                                {{ $t("misc.button.send") }} 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- MESSAGES EMPTY STATE -->
                    <!-- MESSAGES -->
                    <div class="__all__messages" v-if="messageTemplates.length">
                        <div id="__messages__card" class="mnotify-card">
                            <div id="side-indicators-content" class="mnotify-card-header">
                                <h3 class="mnotify-card-header-text py-0 my-0"> {{ $t("dashboard.voice_templates.title") }} </h3>
                            </div>
                            <div class="mnotify-card-body">
                                <div class="__nodata_messages">
                                    <div class=" __nodata_messages table_wrapper">
                                        <el-table :header-cell-style="{ background: 'rgba(212, 216, 226, 0.1)' }"
                                            :data="displayData" ref="singleTable" :height="customHeight">
                                            <el-table-column  :label="$t('misc.table.name')":width="customWidth">
                                                <template slot-scope="scope">
                                                    {{ scope.row.title || scope.row.voice_note_title }}
                                                </template>
                                            </el-table-column>
                                            <el-table-column  :label="$t('misc.table.date_time')" :width="customWidth">
                                                <template slot-scope="scope">
                                                    {{ moment(scope.row.created_at).format('ddd D MMM, YYYY h:mm:ss a') }}
                                                </template>
                                            </el-table-column>
                                            <el-table-column  :label="$t('misc.table.actions')"
                                            style="border:1px solid red;"
                                                :width="customWidth">
                                                <template slot-scope="scope">
                                                    <button
                                                        @click="opendModal({ message: scope.row, key: scope.$index, type: 'send' })"
                                                        class="tb_btn __send send-button"
                                                        title="Send Message With Voice Template">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M19.4354 0.581983C18.9352 0.0685981 18.1949 -0.122663 17.5046 0.0786645L1.408 4.75952C0.679698 4.96186 0.163487 5.54269 0.0244302 6.28055C-0.117628 7.0315 0.378575 7.98479 1.02684 8.38342L6.0599 11.4768C6.57611 11.7939 7.24238 11.7144 7.66956 11.2835L13.4329 5.4843C13.723 5.18231 14.2032 5.18231 14.4934 5.4843C14.7835 5.77623 14.7835 6.24935 14.4934 6.55134L8.71999 12.3516C8.29181 12.7814 8.21178 13.4508 8.52691 13.9702L11.6022 19.0538C11.9623 19.6577 12.5826 20 13.2628 20C13.3429 20 13.4329 20 13.513 19.9899C14.2933 19.8893 14.9135 19.3558 15.1436 18.6008L19.9156 2.52479C20.1257 1.84028 19.9356 1.09537 19.4354 0.581983Z"
                                                                fill="#D4D8E2" class="send-icon" />
                                                        </svg>
                                                    </button>
                                                    <button class="tb_btn __send play-audio"
                                                        @click="opendModal({ message: scope.row, key: scope.$index, type: 'play' })"
                                                        title="Play Voice Message">
                                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M0.466064 10.3014C0.466064 4.78185 4.95448 0.295593 10.4661 0.295593C15.9776 0.295593 20.4661 4.78185 20.4661 10.3014C20.4661 15.8093 15.9776 20.2956 10.4661 20.2956C4.95448 20.2956 0.466064 15.8093 0.466064 10.3014ZM14.134 11.3134C14.2402 11.2073 14.3753 11.0433 14.4043 11.0047C14.5587 10.8021 14.6359 10.5513 14.6359 10.3014C14.6359 10.0206 14.5491 9.76014 14.385 9.54788C14.3715 9.53444 14.3452 9.50578 14.3109 9.46843C14.2467 9.39855 14.1545 9.29823 14.0665 9.21021C13.2749 8.3612 11.2093 6.97191 10.1282 6.5474C9.96413 6.48083 9.54908 6.33515 9.32707 6.3255C9.11471 6.3255 8.91201 6.37374 8.71896 6.47022C8.47765 6.60529 8.2846 6.81754 8.17842 7.06839C8.11085 7.24205 8.00467 7.76303 8.00467 7.77268C7.8985 8.3419 7.84058 9.2681 7.84058 10.2908C7.84058 11.2662 7.8985 12.1528 7.98537 12.7317C7.98776 12.7341 7.99606 12.7755 8.00896 12.8398C8.04817 13.0353 8.12987 13.4427 8.21703 13.6096C8.42939 14.0148 8.84444 14.2657 9.28846 14.2657H9.32707C9.61664 14.256 10.2248 14.0052 10.2248 13.9955C11.2479 13.571 13.2653 12.2493 14.0761 11.3713L14.134 11.3134Z"
                                                                fill="#d4d8e2" />
                                                            <!--was #484A4F -->
                                                        </svg>
                                                    </button>
                                                    <button class="tb_btn _delete delete-button"
                                                        @click="opendModal({ message: scope.row, key: scope.$index, type: 'delete' })">
                                                        <svg width="19" height="21" viewBox="0 0 19 21" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            title="Delete Voice Template">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M17.8648 3.59995C18.255 3.59995 18.5798 3.92002 18.5798 4.32728V4.70382C18.5798 5.10118 18.255 5.43115 17.8648 5.43115H1.24234C0.851189 5.43115 0.526367 5.10118 0.526367 4.70382V4.32728C0.526367 3.92002 0.851189 3.59995 1.24234 3.59995H4.16671C4.76076 3.59995 5.27774 3.18278 5.41138 2.59418L5.56452 1.91839C5.80253 0.997833 6.58581 0.386444 7.48224 0.386444H11.624C12.5106 0.386444 13.3027 0.997833 13.5319 1.86983L13.6958 2.59319C13.8285 3.18278 14.3454 3.59995 14.9405 3.59995H17.8648ZM16.3791 17.3648C16.6845 14.5536 17.219 7.87492 17.219 7.80754C17.2385 7.60341 17.1712 7.41019 17.0376 7.25461C16.8942 7.10895 16.7127 7.02274 16.5128 7.02274H2.60103C2.40009 7.02274 2.20891 7.10895 2.07625 7.25461C1.94163 7.41019 1.8753 7.60341 1.88506 7.80754C1.88685 7.81992 1.90603 8.05518 1.9381 8.44849C2.08055 10.1957 2.47731 15.0621 2.73369 17.3648C2.91512 19.0613 4.04176 20.1275 5.67367 20.1661C6.93297 20.1949 8.2303 20.2048 9.5569 20.2048C10.8064 20.2048 12.0755 20.1949 13.3738 20.1661C15.0623 20.1374 16.188 19.09 16.3791 17.3648Z"
                                                                fill="#d4d8e2" />
                                                        </svg>
                                                    </button>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                        <div class="" style="text-align: center; margin-top:2em;">
                                            <el-pagination background layout="prev, pager, next"
                                                @current-change="handleCurrentChange" :page-size="pageSize" :total="total">
                                            </el-pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- MODAL CONTENT -->
                <!-- DELETE, UPDATE AND VIEW CONTACT MODAL  START -->
                <!-- DELETE, UPDATE AND VIEW CONTACT MODAL END -->
                <messageModal @saveVoiceMessage="saveVoiceMessage" @deleteSuccess="handleDeleteSucccess"
                    :payload="dataPayload" :from='from' @saveVoiceTemplate="saveVoiceTemplate" ref="messageModal" />
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import messageModal from '../messages/messageModal.vue'
import store from '@/state/store.js'
export default {
    name: 'messages',
    components: { messageModal, },
    data() {
        return {
            refreshKey: 0,
            messages: '',
            success: '',
            errors: "",
            modalType: "",
            isDelete: false,
            messageTemplates: [],
            page: 1,
            pageSize: 20,
            total: 0,
            rules: {},
            loadingTemplate: false,
            formData: {
                sederId: "",
                campaigne: "",
                content: ''
            },
            sendMessage: {

            },
            sendMessageRules: {

            },
            from: "",
            dataPayload: {
                currentModal: '',
                selectedKey: '',
                selectedMessage: {

                }
            },
            voiceData: {
                campaign_name: '',
                audio: ""
            }
        }
    },
    computed: {
        searching() {
            if (!this.search) {
                this.total = this.messageTemplates.length;
                return this.messageTemplates;
            }
            this.page = 1;
            return this.messageTemplates.filter(data => data.title.toLowerCase().includes(this.search.toLowerCase()) ||
                data.voice_note_title.toLowerCase().includes(this.search.toLowerCase()))
        },
        displayData() {
            this.total = this.searching.length;
            return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
        },
        customWidth() {
            if (screen.width < 768) {
                return "150px"
            }
            else {
                return "min-content"
            }
        },
        customHeight() {
            if (screen.width < 768) {
                return "50vh"
            }
            else {
                return "60vh"
            }
        }
    },
    methods: {
        handleRefresh() {
            this.getTemplates()
            this.loadingTemplate = false
        },
        handleCurrentChange(val) {
            this.page = val;
        },
        saveVoiceTemplate(data) {
            this.handleRefresh()
            // setTimeout(() => { window.location.reload() }, 200)
        },
        saveVoiceMessage(data) {
            this.messageTemplates.unshift(data)
        },
        scheduleMessage() {
            this.$router.push('/voice/scheduled-message').catch((err) => {
                if (err.name !== "NavigationDuplicated") throw err;
            })
        },
        handleDeleteSucccess(key) {
            this.handleRefresh()
            // setTimeout(() => { 
            //     window.location.reload() }, 100)
        },
        opendModal({ message, key, type }) {
            store.dispatch('modal/addFormData', {
                currentPage: 'voice',
                nextPage: '',
                prevPage: '',
                formData: {
                    campaign_name: message.title,
                    audio: message.body,
                    voice_template_id: message.id,
                    pageTitle: '',
                }
            })
            if (type === 'delete') {
                this.dataPayload = {
                    currentModal: 'delete',
                    selectedKey: key,
                    deleteType: 'voice',
                    selectedMessage: message,
                    deleteMessage: 'Deleting Voice Message will remove it permenantly.'
                }
                store.dispatch('uistate/addContactFormData', {
                    title: "",
                    type: 'delete',
                })
            }
            if (type == 'play') {
                store.dispatch('uistate/addContactFormData', {
                    title: "",
                    type: 'voicePlayer',
                    message: message,
                    key: key
                })
            }
            if (type === 'send') {
                store.dispatch('uistate/setFromVoice', true)
                let payload = {
                    campaign_name: message.title,
                    audio: message.body,
                    isScheduleVoice: true,
                    fromSelectSenderIDForVoice: true,
                    fromVoice: true,
                    hideHeaders: true,
                    voice_template_id: message.id
                }
                store.dispatch('uistate/addContactFormData', {
                    title: "Send Voice Message",
                    type: 'selectSenderId',
                    message: payload
                })
            }
            return this.showModal()
        },
        getTemplates() {
            this.loadingTemplate = true
            store.dispatch('voice/getVoiceTemplates')
                .then(res => {
                    this.loadingTemplate = false
                    this.messageTemplates = res.data
                }).catch(err => {
                    this.loadingTemplate = false
                })
        },
        showModal() {
            let element = this.$refs.messageModal.$el
            $(element).modal('show')
        },
        openMessageModal(type) {
            if (type === 'sendMessage') {
                this.from = 'simpleVoiceMessage'
                this.$emit('SMSType', 'simpleVoiceMessage')
                store.dispatch('uistate/addContactFormData', {
                    title: "Send Message",
                    type: 'selectSenderId',
                    quickVoice: true
                })
                store.dispatch('uistate/setFromScheduled', false)
            } else if (type === 'template') {
                store.dispatch('uistate/setFromVoice', true)
                store.dispatch('uistate/addContactFormData', {
                    title: "Create a voice message",
                    type: 'voiceTemplate',
                })

            }
            return this.showModal()
        },
    },
    mounted() {
        this.getTemplates()
        // Analytics
        this.$mixpanel.track("Voice Messages Page")
        if (this.$store.getters["auth/currentUser"].country === "CI") {
            this.$mixpanelFranco.track("Voice Messages Page")
        }
    },
}
</script>
<style scoped lang="scss">
@import url("../../../styles/messages/___index.scss");

.__create_a_new_template {
    outline: none;
    border: none;
    background: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #484A4F;
    margin-top: 1em;
}

._text_content {
    max-width: 300px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #575761;
}

.is-active-tab {
    color: #f7921c;
    border: 1px solid #f7921c !important;
}

.contains-main-voice-buttons {
    // background-color: springgreen;
    display: flex;
}

.left-side-tab {
    width: 100%;
}

.right-side-tab {
    display: flex;
}

.is-create-button {
    background-color: #f7921c;
    color: white;
    border-radius: 25px;
    // border-color: #f7921c;
}

.play-audio:hover>svg>path {
    fill: #9032a9;
    transition: all .4s ease;
}

.delete-button:hover>svg>path {
    fill: red;
    transition: all .4s ease;
}

.send-button:hover>svg>path {
    fill: #f7921c;
    transition: all .4s ease;
}

.scrollable {
    overflow-y: hidden;
    height: 88vh;
    overflow-x: hidden;
    /* padding-bottom: calc(20vh - 100px); */
}

@media screen and (max-width: 768px) {
    .scrollable {
        height: 100vh;
    }

    .left-side-tab button {
        width: 90%;
        font-size: small;
    }

    .right-side-tab button {
        width: 100%;
        font-size: small;
    }

    .left-side-tab {
        width: 50%;
    }

    .right-side-tab {
        flex-direction: column;
    }
}
</style>